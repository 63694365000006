"use client"

import { useToastStateContext } from "~/app/_context/ToastContext"
import Toast from "./Toast"

export default function ToastContainer() {
    const { toasts } = useToastStateContext()

    return (
        <div className="absolute right-4 top-16 z-50">
            <div className="right-0 max-w-md">
                {toasts?.map((toast) => (
                    <Toast
                        id={toast.id}
                        key={toast.id}
                        type={toast.type}
                        message={toast.message}
                    />
                ))}
            </div>
        </div>
    )
}
